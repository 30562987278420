import http from "@/request/service.js";
/**
 * 获取时间
 */
export function getTime() {
  return new Promise((resolve, reject) => {
    http("get", "/api/v1/health").then(
      (res) => {
        sessionStorage.setItem(
          "time_diff",
          `${res?.data?.now_at - new Date().getTime()}`
        );
        sessionStorage.setItem("rand_str", res?.data?.rand_str);
        resolve(res);
      },
      (error) => {
        console.log("网络异常~", error);
        reject(error);
      }
    );
  });
}
