import { Router, route } from "preact-router";
import { h, Component } from "preact";
// Code-splitting is automated for `routes` directory
import Order from "../routes/order";
import Payer from "../routes/payer";
import Fail from "../routes/fail";
import Index from "../routes/index";
import { getTime } from "@/components/service.js";
// utils
import { setHtmlAttribute, safariDisabledScroll } from "@/utils/helpers";
//const
import { depositType } from "@/routes/home/const";

class App extends Component {
  componentDidMount() {
    safariDisabledScroll();
    getTime();
    console.log(new Date());
  }

  onChangeRoute = (...data) => {
    let type = data[0].current.props?.matches?.type;
    if (!Object.keys(depositType).includes(type)) {
      type = "zfb";
    }
    setHtmlAttribute(type);
  };

  render() {
    return (
      <div id="app">
        <Router onChange={this.onChangeRoute}>
          <Payer path="/payer/:type" />
          <Order path="/order/:type" />
          <Fail path="/fail/:type" />
          <Index path="/" />
        </Router>
      </div>
    );
  }
}

export default App;
