/**
 * 网络请求配置
 */
import axios from "axios";
import md5 from "md5";
import { Toast } from "antd-mobile";

axios.defaults.timeout = 100000;
// axios.defaults.baseURL = "http://kporder.sstjxjkl.com/";
axios.defaults.withCredentials = true;
let _sign;

/**
 * http request 拦截器
 */
axios.interceptors.request.use(
  (config) => {
    const rand_str = sessionStorage.getItem("rand_str");
    const str = `${rand_str}${Math.random().toString(36).slice(-8)}`;
    const time_diff = Number(sessionStorage.getItem("time_diff")) || 0;
    const at = Math.floor((new Date().getTime() + time_diff) / 1000);
    _sign = md5(`${JSON.stringify(config.data)}&at=${at}&str=${str}`);
    config.headers = {
      "Content-Type": "application/json",
    };
    if (config.url !== "/api/v1/health") {
      config.headers["X-API-AT"] = at;
      config.headers["X-API-STR"] = str;
      config.headers["X-API-SIGN"] = _sign;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * http response 拦截器
 */
axios.interceptors.response.use(
  (response) => {
    const {
      data: { sign, status_code, message },
      config,
      status,
    } = response;
    if (status !== 200) {
      throw Error(`请求出错 状态码 ${status}`);
    }
    if (sign && config && config?.url !== "/api/v1/health") {
      delete response?.data?.sign;
      const compare = md5(`${JSON.stringify(response?.data)}${_sign}`);
      if (compare !== sign) {
        throw Error("您的网络存在安全风险，请检查链接的网络是否安全。");
      }
    }
    if (status_code !== 6000) {
      throw Error(message);
    }
    return response;
  },
  (error) => {
    console.log("请求出错：", error);
  }
);

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params })
      .then((response) => {
        landing(url, params, response?.data);
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data) {
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(
      (response) => {
        //关闭进度条
        resolve(response?.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data).then(
      (response) => {
        resolve(response?.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(
      (response) => {
        resolve(response?.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

//统一接口处理，返回数据
export default function (fecth, url, param) {
  return new Promise((resolve, reject) => {
    switch (fecth) {
      case "get":
        get(url, param)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log("get request GET failed.", error);
            reject(error);
          });
        break;
      case "post":
        post(url, param)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log("get request POST failed.", error);
            reject(error);
          });
        break;
      default:
        break;
    }
  });
}

/**
 * 查看返回的数据
 * @param url
 * @param params
 * @param data
 */
function landing(url, params, data) {
  if (data.code === -1) {
  }
}
