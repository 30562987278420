export const depositLabel = {
  bank_username: "收款姓名",
  bank_card: "收款卡号",
  bank_name: "收款银行",
  bank_address: "开户地址",
  // short_order_no: "附言",
  order_no: "订单号",
};

export const depositType = {
  zfb: {
    label: "支付宝APP",
    labelTip: "支付宝",
    way: "（打开支付宝APP-点击转账-转到银行卡-复制上方收款人信息-完成付款）",
  },
  ysf: {
    label: "云闪付APP",
    labelTip: "云闪付",
    way: "（打开云闪付APP-点击转账-转到银行卡-复制上方收款人信息-完成付款）",
  },
  wx: {
    label: "微信APP",
    labelTip: "微信",
    way:
      "（打开微信APP-我的-钱包-收付款-转账到银行卡-复制上方收款人信息-完成付款）",
  },
  wy: {
    label: "手机银行APP",
    labelTip: "银行卡",
    way: "（打开银行APP-点击转账-复制上方收款人信息-完成付款）",
  },
};
