import Big from "big.js";
/**
 * 将数据转为number
 * @param {*} data 需要
 * @return {*} * number / []
 */
function stringToNumber(data) {
  if (typeof data !== "string") {
    return data;
  }
  const res = Number(data);
  return Number.isNaN(res) ? data : res;
}
export function toNumber(data) {
  if (Array.isArray(data)) {
    if (!data.length) {
      return data;
    }
    return data.map((item) => stringToNumber(item));
  }
  return stringToNumber(data);
}

/**
 * 解构react的className数组
 * @param {...string} className
 */
export function reactClassNameJoin(...className) {
  return className.join(" ");
}

/*格式化 数字 使用big.js解决js精度问题
 * @params{len} 支持取几位尾数
 * @params{point} 千分
 * */
export function formatNumber(value, len = 2, point = true) {
  let num = value || 0;
  if (typeof num !== "number") {
    num = parseFloat(num);
    if (typeof num !== "number" || Number.isNaN(num)) {
      return "";
    }
  }
  // const res = len ? new Big(num).toFixed(len) : num;
  const res = len ? new Big(num).toFixed(len + 1).slice(0, -1) : num; // 保留两位小数不四舍五入
  if (!point) {
    return res;
  }
  const [num1, num2] = `${res}`.split(".");
  return (
    `${num1}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    (typeof num2 !== "undefined" ? `.${num2}` : "")
  );
}

export function setHtmlAttribute(name) {
  if (typeof document !== "undefined") {
    document.documentElement.setAttribute("data-theme", name);
  }
}

export function safariDisabledScroll() {
  if (isMobileSafari()) {
    window.addEventListener(
      "touchmove",
      function (event) {
        if (event.scale !== 1) {
          event.preventDefault();
        }
      },
      {
        passive: false,
      }
    );
    var lastTouchEnd = 0;
    window.addEventListener(
      "touchend",
      function (event) {
        const time_diff = Number(sessionStorage.getItem("time_diff")) || 0;
        var now = new Date().getTime() + time_diff;
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      false
    );
    window.addEventListener("gesturestart", function (event) {
      event.preventDefault();
    });
    window.addEventListener("gesturechange", function (event) {
      event.preventDefault();
    });
    window.addEventListener("gestureend", function (event) {
      event.preventDefault();
    });
  }
}

// 移动端的Safari
export function isMobileSafari() {
  if (typeof navigator === "undefined") {
    return false;
  }
  const ua = navigator.userAgent;
  // IOS系统
  if (!/ OS \d/.test(ua)) {
    return false;
  }
  // 不是Chrome
  // tslint:disable-next-line:no-bitwise
  if (~ua.indexOf("CriOS")) {
    return false;
  }
  // 开头不包含为Mozilla
  if (ua.indexOf("Mozilla")) {
    return false;
  }
  // 结尾需为：Safari/xxx.xx
  if (!/Safari\/[\d\.]+$/.test(ua)) {
    return false;
  }
  if (navigator.vendor !== "Apple Computer, Inc.") {
    return false;
  }
  return navigator.userAgent.indexOf("FxiOS") === -1;
}
